import { ComponentSubscriptionCard } from 'types/generated/contentful-types'
import { RichTextContent } from '../design/RichTextContent'
import PricingModels from '../common/PricingModels'

const SubscriptionCard = ({
  heading,
  teaserCopy,
  ctaText,
  ctaUrl,
  urlTarget,
  textOnRight,
  sectionId,
  bulletPoints,
  annualIsLimited,
  annualOriginalPrice,
  annualDiscountPrice,
  annualPriceDetails,
  annualFeaturedText,
  monthlyIsLimited,
  monthlyOriginalPrice,
  monthlyDiscountPrice,
  monthlyPriceDetails,
  monthlyFeaturedText,
  showMultiplePricingModels,
}: ComponentSubscriptionCard) => {
  return (
    <section
      id={sectionId || undefined}
      className="grid items-center grid-cols-1 gap-6 subscription-card lg:grid-cols-5 lg:gap-12"
    >
      <div
        className={`lg:col-span-3 ${
          textOnRight ? 'order-2 lg:justify-self-end' : ''
        }`}
      >
        <h2 className="mb-6 md:mb-8 fora-text-h3">{heading}</h2>
        <RichTextContent richText={teaserCopy} />
      </div>
      <div className="flex items-center justify-center lg:col-span-2">
        <PricingModels
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          urlTarget={urlTarget}
          bulletPoints={bulletPoints}
          annualIsLimited={annualIsLimited}
          annualOriginalPrice={annualOriginalPrice}
          annualDiscountPrice={annualDiscountPrice}
          annualPriceDetails={annualPriceDetails}
          annualFeaturedText={annualFeaturedText}
          monthlyIsLimited={monthlyIsLimited}
          monthlyOriginalPrice={monthlyOriginalPrice}
          monthlyDiscountPrice={monthlyDiscountPrice}
          monthlyPriceDetails={monthlyPriceDetails}
          monthlyFeaturedText={monthlyFeaturedText}
          showMultiplePricingModels={showMultiplePricingModels}
        />
      </div>
    </section>
  )
}

export default SubscriptionCard
